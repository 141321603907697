'use client'

import { HorizontalFeaturedCard, HorizontalFeaturedCardSkeleton } from "./horizontal_featured_card";
import { Suspense, useEffect, useState } from "react";
import { Venue } from "../../../shared/models";
import { Grid } from "@mui/material";
import { FetchAllVenues } from "../lib/firebase/venues";

interface VenuesListProps {
    attributeIcons: Record<string, string>;
}

export default function VenuesList({ attributeIcons }: VenuesListProps) {
    const [venues, setVenues] = useState<Venue[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVenues = async () => {
            console.log('Starting to fetch venues...');
            try {
                // Fetch enabled venues with a large page size to get all at once
                console.log('Calling FetchAllVenues with pageSize: 100');
                const { venues: fetchedVenues } = await FetchAllVenues(100, null, true);
                console.log(`Fetched ${fetchedVenues.length} venues`);

                // Sort the venues by the featured ones first by name if they're called 'the folly', 'little more', 'silence please', 'the pool room'. use an array
                const featuredVenues = ['ketchy shuby', 'little more', 'the folly', 'mudville', 'the pool room', 'silence please', 'b lounge nyc', 'la vibra'];

                const sortedVenues = fetchedVenues.sort((a, b) => {
                    if (featuredVenues.includes(a.Name.toLowerCase())) {
                        return -1;
                    }
                    if (featuredVenues.includes(b.Name.toLowerCase())) {
                        return 1;
                    }
                    return a.Name.localeCompare(b.Name.toLowerCase());
                });
                setVenues(sortedVenues);
            } catch (error) {
                console.error('Error fetching venues:', error);
            } finally {
                console.log('Finished fetching venues, setting loading to false');
                setLoading(false);
            }
        };

        fetchVenues();
    }, []);

    if (loading) {
        return (
            <Grid container spacing={4} className='min-h-[calc(100vh-64px)]'>
                {[...Array(8)].map((_, index) => (
                    <HorizontalFeaturedCardSkeleton key={`skeleton-${index}`} />
                ))}
            </Grid>
        );
    }

    return (
        <Grid container spacing={4} className='min-h-[calc(100vh-64px)]'>
            {venues.map((venue, index) => (
                <Suspense key={venue.ID || `venue-${index}`} fallback={<div className="h-[200px] bg-gray-800 animate-pulse rounded-lg" />}>
                    <HorizontalFeaturedCard
                        venue={venue}
                        attributeIcons={attributeIcons}
                        venueBookedTimeSlots={{}}
                    />
                </Suspense>
            ))}
        </Grid>
    );
}