'use client';
import React, { useState, useCallback, useEffect, useMemo, memo, useRef } from 'react';
import {
  Box,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  Button,
  Popover,
  Typography,
  Chip,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from 'next/navigation';
import { fetchAllVenueMetadata } from '../lib/firebase/venues';
import dayjs, { Dayjs } from 'dayjs';
import { SelectDatePicker } from './select_date_picker';
import { COMMON_ICONS, IconNames } from '../lib/icon-utils';
import { getAttributeIcons } from '../lib/firebase/metadata';
import { useSearchSettingsStore } from '../stores/search_settings_store';

interface SearchFiltersProps {
  onFilterChange: () => void;
}

// Main component
export const SearchFilters: React.FC<SearchFiltersProps> = memo(({
  onFilterChange,
}) => {
  const theme = useTheme();
  const searchParams = useSearchParams();

  // Use the Zustand store instead of local state
  const {
    selectedDate,
    priceRange,
    people,
    selectedAttributes,
    selectedTime,
    neighborhoods,
    setSelectedDate,
    setPriceRange,
    setPeople,
    setSelectedTime,
    toggleAttribute,
    toggleNeighborhood,
    initializeFromUrl
  } = useSearchSettingsStore();

  const [possibleAttributes, setPossibleAttributes] = useState<string[]>([]);
  const [attributeIcons, setAttributeIcons] = useState<Record<string, string>>({});
  const [iconComponents, setIconComponents] = useState<Record<string, React.ComponentType>>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [localPeople, setLocalPeople] = useState<string>('');
  const peopleDebounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Define priorityAttributes with neighborhoods
  const priorityAttributes = [
    'wedding', 'corporate', 'no minimum spend', 'bar', 'restaurant', 'fancy', 'casual',
    'intimate gathering'
  ];

  // Define neighborhoods separately
  const neighborhoodsList = [
    'greenwich village', 'midtown', 'east village', 'queens', 'soho',
    'williamsburg', 'brooklyn', 'lower east side', 'lower manhattan'
  ];

  // State for dynamically loaded icons
  const [FilterListIcon, setFilterListIcon] = useState<React.ComponentType | null>(null);
  const [PeopleIcon, setPeopleIcon] = useState<React.ComponentType | null>(null);

  // Load the required icons on component mount
  useEffect(() => {
    let isMounted = true;
    const loadIcons = async () => {
      try {
        const [filterListIconModule, peopleIconModule] = await Promise.all([
          COMMON_ICONS.FilterList(),
          COMMON_ICONS.People()
        ]);

        if (isMounted) {
          setFilterListIcon(() => filterListIconModule);
          setPeopleIcon(() => peopleIconModule);
        }
      } catch (error) {
        console.error('Error loading icons:', error);
      }
    };

    loadIcons();
    return () => { isMounted = false; };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchAttributes = async () => {
      try {
        const AllVenueMetadata = await fetchAllVenueMetadata();
        if (!isMounted) return;

        let attributes = AllVenueMetadata.possibleAttributes;
        // remove duplicates using Array.from() instead of spread operator
        attributes = Array.from(new Set(attributes));

        // Sort attributes according to priority, but exclude neighborhoods
        const sortedAttributes: string[] = [];

        // Add priority attributes first
        priorityAttributes.forEach(attr => {
          if (attributes.includes(attr)) {
            sortedAttributes.push(attr);
          }
        });

        // Get remaining attributes that are not neighborhoods
        const remainingAttributes = attributes.filter(attr =>
          !priorityAttributes.includes(attr) && !neighborhoodsList.includes(attr)
        );

        // Sort remaining attributes with icons
        const withIcons = remainingAttributes.filter(attr => attributeIcons[attr]).sort();
        sortedAttributes.push(...withIcons);

        // Sort remaining attributes without icons
        const withoutIcons = remainingAttributes.filter(attr => !attributeIcons[attr]).sort();
        sortedAttributes.push(...withoutIcons);

        setPossibleAttributes(sortedAttributes);
      } catch (error) {
        console.error('Error fetching attributes:', error);
      }
    };
    fetchAttributes();
    return () => { isMounted = false; };
  }, [attributeIcons]);

  useEffect(() => {
    let isMounted = true;
    const fetchIcons = async () => {
      try {
        const icons = await getAttributeIcons();
        if (!isMounted) return;

        setAttributeIcons(icons);

        // Load icon components for each attribute - use a worker if available
        const loadIconComponents = async () => {
          const loadedIcons: Record<string, React.ComponentType> = {};
          const uniqueIconNames = Array.from(new Set(Object.values(icons))) as string[];

          // Use Promise.all to load icons in parallel
          await Promise.all(
            uniqueIconNames.map(async (iconName) => {
              if (iconName && COMMON_ICONS[iconName as IconNames]) {
                try {
                  const IconComponent = await COMMON_ICONS[iconName as IconNames]();
                  if (isMounted) {
                    loadedIcons[iconName] = IconComponent;
                  }
                } catch (error) {
                  console.error(`Failed to load icon: ${iconName}`, error);
                }
              }
            })
          );

          if (isMounted) {
            setIconComponents(loadedIcons);
          }
        };

        loadIconComponents();
      } catch (error) {
        console.error('Error fetching icons:', error);
      }
    };
    fetchIcons();
    return () => { isMounted = false; };
  }, []);

  // Initialize store from URL when component mounts
  useEffect(() => {
    if (searchParams) {
      initializeFromUrl(searchParams);
    }
  }, [searchParams, initializeFromUrl]);

  // Initialize localPeople from the store value when component mounts or people changes
  useEffect(() => {
    setLocalPeople(people);
  }, [people]);

  const handleDateChange = useCallback(
    (newDate: Dayjs | null) => {
      setSelectedDate(newDate);
      onFilterChange();
    },
    [setSelectedDate, onFilterChange]
  );

  const handlePriceRangeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newPriceRange = event.target.value;
      setPriceRange(newPriceRange);
      onFilterChange();
    },
    [setPriceRange, onFilterChange]
  );

  const handlePeopleChange = useCallback(
    (value: string) => {
      // Validate input to only allow numbers
      if (value && !/^\d+$/.test(value)) {
        return; // Only allow digits
      }

      // Immediately update local state for responsive UI
      setLocalPeople(value);

      // Clear any existing timer
      if (peopleDebounceTimerRef.current) {
        clearTimeout(peopleDebounceTimerRef.current);
      }

      // Set a new timer to update the actual state after a delay
      peopleDebounceTimerRef.current = setTimeout(() => {
        setPeople(value);
        onFilterChange();
        peopleDebounceTimerRef.current = null;
      }, 1000); // Increased to 1000ms for more stable debouncing
    },
    [setPeople, onFilterChange]
  );

  // Clean up the timer when component unmounts
  useEffect(() => {
    return () => {
      if (peopleDebounceTimerRef.current) {
        clearTimeout(peopleDebounceTimerRef.current);
        peopleDebounceTimerRef.current = null;
      }
    };
  }, []);

  const handleTimeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newSelectedTime = event.target.value;
      setSelectedTime(newSelectedTime);
      onFilterChange();
    },
    [setSelectedTime, onFilterChange]
  );

  const handleAttributeClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleAttributeClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAttributeSelect = useCallback((attribute: string) => {
    // Regular attribute
    toggleAttribute(attribute);
    onFilterChange();
  }, [toggleAttribute, onFilterChange]);

  // Handle neighborhood selection separately
  const handleNeighborhoodSelect = useCallback((neighborhood: string) => {
    toggleNeighborhood(neighborhood);
    onFilterChange();
  }, [toggleNeighborhood, onFilterChange]);

  const open = Boolean(anchorEl);
  const id = open ? 'attribute-popover' : undefined;

  // Get rid of duplicate declarations by keeping only one set of memoized options
  const priceRangeOptions = useMemo(() => [
    <MenuItem key="price-default" value="">Select price range</MenuItem>,
    <MenuItem key="price-free" value="0-0">Free (No Minimum)</MenuItem>,
    <MenuItem key="price-0-40" value="0-40">$0 - $40 per person</MenuItem>,
    <MenuItem key="price-0-70" value="0-70">$0 - $70 per person</MenuItem>,
    <MenuItem key="price-0-100" value="0-100">$0 - $100 per person</MenuItem>,
    <MenuItem key="price-0-150" value="0-150">$0 - $150 per person</MenuItem>,
    <MenuItem key="price-0-200" value="0-200">$0 - $200 per person</MenuItem>,
    <MenuItem key="price-200-plus" value="200-10000">$200+ per person</MenuItem>
  ], []);

  const timeOptions = useMemo(() => [
    <MenuItem key="time-default" value="">Select time</MenuItem>,
    <MenuItem key="time-17-00" value="17:00">5:00 PM</MenuItem>,
    <MenuItem key="time-17-30" value="17:30">5:30 PM</MenuItem>,
    <MenuItem key="time-18-00" value="18:00">6:00 PM</MenuItem>,
    <MenuItem key="time-18-30" value="18:30">6:30 PM</MenuItem>,
    <MenuItem key="time-19-00" value="19:00">7:00 PM</MenuItem>,
    <MenuItem key="time-19-30" value="19:30">7:30 PM</MenuItem>,
    <MenuItem key="time-20-00" value="20:00">8:00 PM</MenuItem>,
    <MenuItem key="time-20-30" value="20:30">8:30 PM</MenuItem>,
    <MenuItem key="time-21-00" value="21:00">9:00 PM</MenuItem>
  ], []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        width: '100%',
        pt: 2,
        pb: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: '800px' }}>
        <SelectDatePicker
          minWidth='100px'
          label='Date'
          value={selectedDate}
          onChange={handleDateChange}
          slotProps={{
            field: {
              sx: {
                backgroundColor: '#e3f2fd',
                borderRadius: '8px 0 0 8px',
                height: '40px',
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  '& fieldset': {
                    borderRight: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '8px',
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                },
              },
            },
          }}
        />
        <Select
          value={priceRange}
          onChange={handlePriceRangeChange}
          displayEmpty
          sx={{
            backgroundColor: '#EDE7F6',
            borderRadius: '0',
            flex: 1,
            minWidth: { xs: '120px', sm: '160px' },
            height: '40px',
            fontSize: { xs: '0.8rem', sm: '1rem' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderLeft: 'none',
              borderRight: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }}
        >
          {priceRangeOptions}
        </Select>
        <Select
          value={selectedTime || ''}
          onChange={handleTimeChange}
          displayEmpty
          sx={{
            backgroundColor: '#BBDEFB',
            borderRadius: '0',
            flex: 1,
            minWidth: { xs: '110px', sm: '140px' },
            height: '40px',
            fontSize: { xs: '0.8rem', sm: '1rem' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderLeft: 'none',
              borderRight: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }}
        >
          {timeOptions}
        </Select>
        <TextField
          value={localPeople}
          onChange={(e) => handlePeopleChange(e.target.value)}
          placeholder="People"
          type="number"
          InputProps={{
            startAdornment: PeopleIcon ? (
              <InputAdornment position="start">
                {/* Fix for the fontSize error - use MUI's style prop instead */}
                {PeopleIcon && <PeopleIcon />}
              </InputAdornment>
            ) : null,
            // Add input validation to only allow numbers
            inputProps: {
              min: 1,
              max: 1000,
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }
          }}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: '0',
              height: '40px',
              backgroundColor: '#D1C4E9',
              '& fieldset': {
                borderLeft: 'none',
                borderRight: 'none',
              },
            },
            '& .MuiInputBase-input': {
              padding: '8px',
              width: { xs: '60px', sm: '80px' },
              fontSize: { xs: '0.8rem', sm: '1rem' },
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          }}
        />
        <Button
          onClick={handleAttributeClick}
          variant='contained'
          aria-describedby={id}
          startIcon={FilterListIcon && <FilterListIcon />}
          sx={{
            backgroundColor: '#9575CD',
            borderRadius: '0 8px 8px 0',
            height: '40px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#7E57C2',
            },
            '& .MuiButton-startIcon': {
              marginRight: { xs: 0, sm: 0.5 },
            },
            fontSize: { xs: '0.75rem', sm: '0.85rem' },
            padding: { xs: '0 8px', sm: '0 16px' },
          }}
        >
          {selectedAttributes?.length || neighborhoods?.length ? `Filters (${(selectedAttributes?.length || 0) + (neighborhoods?.length || 0)})` : 'Filters'}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleAttributeClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPopover-paper': {
              width: '300px',
              maxHeight: '90vh',
              padding: '16px',
              backgroundColor: '#2c2c54',
              color: 'white',
            },
          }}
        >
          {/* Neighborhoods Section */}
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1, borderBottom: '1px solid rgba(255,255,255,0.2)', pb: 1 }}>
            Neighborhoods
          </Typography>
          <Box sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', gap: 1, maxHeight: '250px', overflowY: 'auto' }}>
            {neighborhoodsList.map((neighborhood) => {
              const isSelected = neighborhoods?.includes(neighborhood) || false;
              const iconName = attributeIcons[neighborhood];
              const IconComponent = iconName ? iconComponents[iconName] : null;

              return (
                <Chip
                  key={neighborhood}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {IconComponent && <span style={{ display: 'flex', alignItems: 'center' }}>{React.createElement(IconComponent)}</span>}
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{neighborhood}</Typography>
                    </Box>
                  }
                  onClick={() => handleNeighborhoodSelect(neighborhood)}
                  variant={isSelected ? "filled" : "outlined"}
                  sx={{
                    margin: '2px',
                    backgroundColor: isSelected ? '#7E57C2' : 'transparent',
                    color: isSelected ? 'white' : 'rgba(255, 255, 255, 0.7)',
                    borderColor: isSelected ? '#7E57C2' : 'rgba(255, 255, 255, 0.3)',
                    '&:hover': {
                      backgroundColor: isSelected ? '#6A1B9A' : 'rgba(255, 255, 255, 0.1)',
                    },
                    height: '28px',
                  }}
                />
              );
            })}
          </Box>

          {/* Attributes Section */}
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1, borderBottom: '1px solid rgba(255,255,255,0.2)', pb: 1 }}>
            Venue Attributes
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, maxHeight: '300px', overflowY: 'auto' }}>
            {possibleAttributes.map((attribute) => {
              const isSelected = selectedAttributes?.includes(attribute) || false;
              const iconName = attributeIcons[attribute];
              const IconComponent = iconName ? iconComponents[iconName] : null;

              return (
                <Chip
                  key={attribute}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {IconComponent && <span style={{ display: 'flex', alignItems: 'center' }}>{React.createElement(IconComponent)}</span>}
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{attribute}</Typography>
                    </Box>
                  }
                  onClick={() => handleAttributeSelect(attribute)}
                  variant={isSelected ? "filled" : "outlined"}
                  sx={{
                    margin: '2px',
                    backgroundColor: isSelected ? '#7E57C2' : 'transparent',
                    color: isSelected ? 'white' : 'rgba(255, 255, 255, 0.7)',
                    borderColor: isSelected ? '#7E57C2' : 'rgba(255, 255, 255, 0.3)',
                    '&:hover': {
                      backgroundColor: isSelected ? '#6A1B9A' : 'rgba(255, 255, 255, 0.1)',
                    },
                    height: '28px',
                  }}
                />
              );
            })}
          </Box>
        </Popover>
      </Box>
    </Box>
  );
});

SearchFilters.displayName = 'SearchFilters';
