'use client'

import React, { memo } from 'react';
import { Box } from '@mui/material';
import { DiscoBallWithConsistentLoading } from './disco_ball';
import LinkWithLoading from './link_with_loading';

const SmallLogo = memo(() => (
    <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', position: 'relative', ml: -2 }}>
        <Box sx={{ position: 'absolute', width: '60px', height: '60px', left: 0, top: '50%', transform: 'translateY(-50%)' }}>
            <DiscoBallWithConsistentLoading quality="low" size="small" />
        </Box>
        <LinkWithLoading href='/' passHref>
            <Box
                component="img"
                src="/img/litty_logo_v2_twostars_shadow.png"
                alt="Litty Logo"
                sx={{
                    width: '45px',
                    height: 'auto',
                    position: 'absolute',
                    zIndex: 2,
                    transform: 'translateY(10px)',
                    marginLeft: '33px',
                    marginTop: '-18px',
                }}
            />
        </LinkWithLoading>
    </Box>
));

SmallLogo.displayName = 'SmallLogo';

export default SmallLogo;
