'use client'

import React, { useEffect, useState } from 'react';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import VenueShowcaseScrollable from './venue_showcase_scrollable';
import VenuesList from './venues_list';
import { HomepageCategory, Venue } from '../../../shared/models';
import { getAllHomepageCategories } from '../lib/firebase/homepage_category';
import { HorizontalFeaturedCardSkeleton } from './horizontal_featured_card';

interface VenuesShowcaseProps {
    attributeIcons: Record<string, string>;
    venues: Venue[];
}

const VenuesShowcase: React.FC<VenuesShowcaseProps> = ({ attributeIcons, venues }) => {
    const [homepageCategory, setHomepageCategory] = useState<HomepageCategory | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [mounted, setMounted] = useState(false);
    const isDesktop = useMediaQuery('(min-width:768px)');

    // Handle mounting state to prevent hydration mismatch
    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categories = await getAllHomepageCategories();
                const nyCategory = categories.find(cat => cat.ID === 'New York');
                if (nyCategory) {
                    nyCategory.CategoriesAndVenuesIDsInOrder.forEach(category => {
                        category.VenueIDs = category.VenueIDs.sort(() => Math.random() - 0.5);
                    });
                    setHomepageCategory(nyCategory);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCategories();
    }, []);

    if (!mounted) {
        return (
            <Box sx={{ width: '100%' }}>
                {/* Simple loading skeleton */}
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={400}
                    sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }}
                />
            </Box>
        );
    }

    if (!homepageCategory || isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                {
                    [...Array(3)].map((_, categoryIndex) => (
                        <Box key={categoryIndex} sx={{ mb: 6 }}>
                            {/* Category title skeleton */}
                            <Skeleton
                                variant="text"
                                sx={{
                                    mb: 2,
                                    width: '300px',
                                    height: '3rem',
                                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                                }}
                            />
                            {/* Subtitle skeleton */}
                            <Skeleton
                                variant="text"
                                sx={{
                                    mb: 4,
                                    width: '500px',
                                    height: '1.5rem',
                                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                                }}
                            />
                            {/* Cards skeleton container */}
                            <Box sx={{
                                display: 'flex',
                                gap: {
                                    xs: 7, // 8px on mobile
                                    sm: 8, // 12px on tablet
                                    md: 9 // 16px on desktop
                                },
                                overflow: 'hidden'
                            }}>
                                {[...Array(6)].map((_, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            minWidth: { xs: '300px', sm: '350px', md: '400px' },
                                            maxWidth: { xs: '300px', sm: '350px', md: '400px' },
                                        }}
                                    >
                                        <HorizontalFeaturedCardSkeleton />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))
                }
            </Box >
        );
    }

    return (
        <div className="w-full">
            {isDesktop ? (
                <VenueShowcaseScrollable
                    categoriesAndVenuesIDsInOrder={homepageCategory.CategoriesAndVenuesIDsInOrder}
                    attributeIcons={attributeIcons}
                    venues={venues}
                />
            ) : (
                <VenuesList
                    attributeIcons={attributeIcons}
                />
            )}
        </div>
    );
};

export default VenuesShowcase;
