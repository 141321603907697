'use client';

import React, { useState, useMemo, useCallback } from 'react';
import { Box, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import CalendarDay from './custom_avail_calendar/calendar_day_item';

interface SmallCustomCalendarProps {
    selectedDate: Date;
    minDate?: Date;
    maxDate?: Date;
    onDateChange: (date: Date) => void;
}

const SmallCustomCalendar = ({
    selectedDate,
    minDate,
    maxDate,
    onDateChange
}: SmallCustomCalendarProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentMonth, setCurrentMonth] = useState(() => new Date(selectedDate));
    const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    const calendarDays = useMemo(() => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        const firstDay = new Date(year, month, 1, 0, 0, 0, 0);
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const startDay = firstDay.getDay();

        return [
            ...Array(startDay).fill(null).map((_, i) => {
                const date = new Date(year, month, -startDay + i + 1);
                date.setHours(0, 0, 0, 0);
                return date;
            }),
            ...Array(daysInMonth).fill(null).map((_, i) => {
                const date = new Date(year, month, i + 1);
                date.setHours(0, 0, 0, 0);
                return date;
            }),
            ...Array(42 - (startDay + daysInMonth)).fill(null).map((_, i) => {
                const date = new Date(year, month + 1, i + 1);
                date.setHours(0, 0, 0, 0);
                return date;
            })
        ];
    }, [currentMonth]);

    const isDateDisabled = useCallback((date: Date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!date) return true;

        const compareDate = new Date(date);
        compareDate.setHours(0, 0, 0, 0);

        const isBeforeToday = compareDate.getTime() < today.getTime();
        const isBeforeMinDate = minDate && compareDate.getTime() < minDate.getTime();
        const isAfterMaxDate = maxDate && compareDate.getTime() > maxDate.getTime();

        return isBeforeToday || isBeforeMinDate || isAfterMaxDate;
    }, [minDate, maxDate]);

    const handleMonthChange = useCallback((increment: number) => {
        setCurrentMonth(prevMonth => {
            const newDate = new Date(prevMonth);
            newDate.setMonth(prevMonth.getMonth() + increment);
            newDate.setHours(0, 0, 0, 0);

            // Don't allow going before min date's month
            if (minDate) {
                const minMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
                if (newDate < minMonth) {
                    return prevMonth;
                }
            }

            return newDate;
        });
    }, [minDate]);

    const isLeftArrowDisabled = useMemo(() => {
        if (!minDate) return false;
        const currentFirstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const minFirstDay = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
        return currentFirstDay <= minFirstDay;
    }, [currentMonth, minDate]);

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            bgcolor: '#2c2c54',
            p: { xs: 2, sm: 3 },
            boxShadow: { xs: 'none', sm: '0px 4px 16px rgba(0, 0, 0, 0.2)' },
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: { xs: 2, sm: 3 },
                py: 1
            }}>
                <IconButton
                    onClick={() => handleMonthChange(-1)}
                    size={isMobile ? "small" : "medium"}
                    disabled={isLeftArrowDisabled}
                    sx={{
                        color: '#FFFFFF',
                        '&.Mui-disabled': {
                            color: 'rgba(255, 255, 255, 0.3)'
                        },
                        mx: 1
                    }}
                >
                    <ChevronLeft fontSize={isMobile ? "medium" : "large"} />
                </IconButton>
                <Typography
                    variant={isMobile ? "subtitle1" : "h6"}
                    sx={{
                        fontWeight: 600,
                        color: '#FFFFFF',
                        mx: 2,
                        fontFamily: 'Sans',
                        letterSpacing: '0.5px'
                    }}
                >
                    {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </Typography>
                <IconButton
                    onClick={() => handleMonthChange(1)}
                    size={isMobile ? "small" : "medium"}
                    sx={{
                        color: '#FFFFFF',
                        mx: 1
                    }}
                >
                    <ChevronRight fontSize={isMobile ? "medium" : "large"} />
                </IconButton>
            </Box>

            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                width: '100%',
                gap: { xs: 0.5, sm: 1 },
                flex: 1,
                '& .MuiTypography-root': {
                    color: '#FFFFFF',
                }
            }}>
                {weekDays.map(day => (
                    <Typography
                        key={day}
                        variant="subtitle2"
                        align="center"
                        sx={{
                            fontWeight: 600,
                            color: '#E0B1F1',
                            mb: { xs: 1, sm: 1.5 },
                            fontSize: { xs: '0.8rem', sm: '0.95rem' },
                            fontFamily: 'Sans'
                        }}
                    >
                        {day}
                    </Typography>
                ))}

                {calendarDays.map((date, i) => (
                    <Box
                        key={i}
                        sx={{
                            p: { xs: '0', sm: '2px' },
                            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                            '&:hover': {
                                transform: !isDateDisabled(date) ? 'scale(1.08)' : 'none',
                                boxShadow: !isDateDisabled(date) ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',
                                zIndex: 1
                            }
                        }}
                    >
                        <CalendarDay
                            date={date}
                            isDisabled={isDateDisabled(date)}
                            isSelected={date && selectedDate && date.toDateString() === selectedDate.toDateString()}
                            isCurrentMonth={date && date.getMonth() === currentMonth.getMonth()}
                            onClick={() => date && !isDateDisabled(date) && onDateChange(date)}
                            section=""
                            sectionAvailabilities={[{
                                id: "default",
                                name: "Available",
                                color: "#F0D1FF",
                                timeRanges: [{
                                    start: "00:00",
                                    end: "23:59"
                                }]
                            }]}
                            sectionBookedTimes={[]}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default SmallCustomCalendar;
