'use client';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  Popover,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  IconButton,
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from 'next/navigation';
import { fetchAllVenueMetadata } from '../lib/firebase/venues';
import dayjs, { Dayjs } from 'dayjs';
import { COMMON_ICONS, IconNames } from '../lib/icon-utils';
import { getAttributeIcons } from '../lib/firebase/metadata';
import {
  LocalizationProvider,
  loadAdapterDayjs
} from './lazy/date-pickers';
import { useSearchSettingsStore } from '../stores/search_settings_store';
import SmallCustomCalendar from './small_custom_calendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider as MuiXLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';

interface SearchFiltersWithPopupProps {
  onFilterChange: () => void;
}

export const SearchFiltersWithPopup: React.FC<SearchFiltersWithPopupProps> = ({
  onFilterChange,
}) => {
  const theme = useTheme();
  const searchParams = useSearchParams();

  // Use the Zustand store instead of local state
  const {
    selectedDate,
    priceRange,
    people,
    zipCode,
    selectedAttributes,
    selectedTime,
    neighborhoods,
    setSelectedDate,
    setPriceRange,
    setPeople,
    setZipCode,
    setSelectedTime,
    toggleAttribute,
    toggleNeighborhood,
    initializeFromUrl
  } = useSearchSettingsStore();

  const [possibleAttributes, setPossibleAttributes] = useState<string[]>([]);
  const [attributeIcons, setAttributeIcons] = useState<Record<string, string>>({});
  const [iconComponents, setIconComponents] = useState<Record<string, React.ComponentType>>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // State for dynamically loaded icons
  const [FilterListIcon, setFilterListIcon] = useState<React.ComponentType | null>(null);
  const [LocationOnIcon, setLocationOnIcon] = useState<React.ComponentType | null>(null);
  const [PeopleIcon, setPeopleIcon] = useState<React.ComponentType | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  // Define location types for neighborhoods
  const locationTypes = [
    'greenwich village', 'midtown', 'east village', 'queens', 'soho',
    'williamsburg', 'brooklyn', 'lower east side', 'lower manhattan'
  ];

  // Define non-location attributes to be shown separately
  const attributeTypes = [
    'wedding', 'corporate', 'no minimum spend', 'bar', 'restaurant', 'fancy', 'casual',
    'intimate gathering'
  ];

  // Load the required icons on component mount
  useEffect(() => {
    const loadIcons = async () => {
      const filterListIconModule = await COMMON_ICONS.FilterList();
      const locationOnIconModule = await COMMON_ICONS.LocationOn();
      const peopleIconModule = await COMMON_ICONS.People();

      setFilterListIcon(() => filterListIconModule);
      setLocationOnIcon(() => locationOnIconModule);
      setPeopleIcon(() => peopleIconModule);
    };

    loadIcons();
  }, []);

  useEffect(() => {
    const fetchAttributes = async () => {
      const AllVenueMetadata = await fetchAllVenueMetadata();
      const attributes = AllVenueMetadata.possibleAttributes.filter(
        attr => !locationTypes.includes(attr.toLowerCase())
      );
      setPossibleAttributes(attributes);
    };
    fetchAttributes();
  }, [locationTypes]);

  useEffect(() => {
    const fetchIcons = async () => {
      const icons = await getAttributeIcons();
      setAttributeIcons(icons);

      // Load icon components for each attribute
      const loadIconComponents = async () => {
        const loadedIcons: Record<string, React.ComponentType> = {};
        const uniqueIconNames = Array.from(new Set(Object.values(icons))) as string[];
        for (const iconName of uniqueIconNames) {
          if (iconName && COMMON_ICONS[iconName as IconNames]) {
            try {
              const IconComponent = await COMMON_ICONS[iconName as IconNames]();
              loadedIcons[iconName] = IconComponent;
            } catch (error) {
              console.error(`Failed to load icon: ${iconName}`, error);
            }
          }
        }
        setIconComponents(loadedIcons);
      };

      loadIconComponents();
    };
    fetchIcons();
  }, []);

  // Initialize store from URL when component mounts
  useEffect(() => {
    if (searchParams) {
      initializeFromUrl(searchParams);
    }
  }, [searchParams, initializeFromUrl]);

  const handleDateChange = useCallback(
    (newDate: Dayjs | null) => {
      setSelectedDate(newDate);
      onFilterChange();
    },
    [setSelectedDate, onFilterChange]
  );

  const handlePriceRangeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newPriceRange = event.target.value;
      setPriceRange(newPriceRange);
      onFilterChange();
    },
    [setPriceRange, onFilterChange]
  );

  const [localPeople, setLocalPeople] = useState<string>('');
  const peopleDebounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Initialize localPeople from the store value when component mounts or people changes
  useEffect(() => {
    setLocalPeople(people);
  }, [people]);

  const handlePartySizeChange = useCallback(
    (value: string) => {
      // Immediately update local state for responsive UI
      setLocalPeople(value);

      // Clear any existing timer
      if (peopleDebounceTimerRef.current) {
        clearTimeout(peopleDebounceTimerRef.current);
      }

      // Set a new timer to update the actual state after a delay
      peopleDebounceTimerRef.current = setTimeout(() => {
        setPeople(value);
        onFilterChange();
        peopleDebounceTimerRef.current = null;
      }, 800); // 800ms delay for debouncing
    },
    [setPeople, onFilterChange]
  );

  // Clean up the timer when component unmounts
  useEffect(() => {
    return () => {
      if (peopleDebounceTimerRef.current) {
        clearTimeout(peopleDebounceTimerRef.current);
      }
    };
  }, []);

  const handleZipCodeChange = useCallback(
    (value: string) => {
      setZipCode(value);
      onFilterChange();
    },
    [setZipCode, onFilterChange]
  );

  const handleTimeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newSelectedTime = event.target.value;
      setSelectedTime(newSelectedTime);
      onFilterChange();
    },
    [setSelectedTime, onFilterChange]
  );

  const handleAttributeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAttributeClose = () => {
    setAnchorEl(null);
  };

  // Split the attribute selection into two separate handlers
  const handleAttributeSelect = (attribute: string) => {
    toggleAttribute(attribute);
    onFilterChange();
  };

  const handleNeighborhoodSelect = (neighborhood: string) => {
    toggleNeighborhood(neighborhood);
    onFilterChange();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'attribute-popover' : undefined;

  // Dayjs adapter loading for date picker
  const [adapter, setAdapter] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load the adapter dynamically
  useEffect(() => {
    async function loadAdapter() {
      setIsLoading(true);
      const AdapterClass = await loadAdapterDayjs();
      setAdapter(new AdapterClass());
      setIsLoading(false);
    }

    loadAdapter();
  }, []);

  // Filter the possible attributes to exclude neighborhoods
  const filteredAttributes = useMemo(() => {
    return possibleAttributes.filter(attr => !locationTypes.includes(attr.toLowerCase()));
  }, [possibleAttributes, locationTypes]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0%',
        width: '100%',
        pt: 2,
        pb: 1,
      }}
    >
      {!isLoading && (
        <LocalizationProvider dateAdapter={adapter?.constructor as any}>
          <Box
            onClick={() => setIsDatePickerOpen(true)}
            sx={{
              cursor: 'pointer',
              p: 1,
              borderRadius: '8px 0 0 8px',
              bgcolor: 'rgba(224, 177, 241, 0.1)',
              border: '1px solid #E0B1F1',
              borderRight: 'none',
              WebkitTapHighlightColor: 'transparent',
              touchAction: 'manipulation',
              userSelect: 'none',
              minHeight: '40px',
              minWidth: '120px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
              '&:active': {
                backgroundColor: 'rgba(224, 177, 241, 0.2)',
              }
            }}
          >
            <Typography sx={{ fontSize: '0.9rem', color: '#E0B1F1' }}>
              {selectedDate ? selectedDate.format('MMM D, YYYY') : 'Select a date'}
            </Typography>
          </Box>
          {isDatePickerOpen && (
            <Box
              className="date-picker-container"
              onClick={(e) => e.stopPropagation()}
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1200,
                bgcolor: 'rgba(0, 0, 0, 0.85)',
                backdropFilter: 'blur(8px)',
                padding: { xs: 2, sm: 4 }
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '450px',
                  bgcolor: '#2c2c54',
                  borderRadius: '16px',
                  border: '2px solid #E0B1F1',
                  boxShadow: '0 16px 32px rgba(224, 177, 241, 0.6), 0 8px 16px rgba(0, 0, 0, 0.4)',
                  overflow: 'hidden',
                  animation: 'fadeIn 0.3s ease-out',
                  '@keyframes fadeIn': {
                    '0%': { opacity: 0, transform: 'scale(0.95)' },
                    '100%': { opacity: 1, transform: 'scale(1)' }
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid rgba(224, 177, 241, 0.3)'
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#fff',
                      fontWeight: 600
                    }}
                  >
                    Select Date
                  </Typography>
                  <IconButton
                    onClick={() => setIsDatePickerOpen(false)}
                    sx={{ color: '#fff' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <SmallCustomCalendar
                  selectedDate={selectedDate ? selectedDate.toDate() : new Date()}
                  minDate={new Date()}
                  onDateChange={(newDate) => {
                    if (newDate) {
                      setSelectedDate(dayjs(newDate));
                      setIsDatePickerOpen(false);
                    }
                  }}
                />
              </Box>
            </Box>
          )}
        </LocalizationProvider>
      )}
      <Button
        aria-describedby={id}
        onClick={handleAttributeClick}
        sx={{
          backgroundColor: '#E6E6FA',
          borderRadius: '0 8px 8px 0',
          height: '40px',
          width: '40px',
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#D8BFD8',
          },
        }}
      >
        {FilterListIcon && <FilterListIcon />}
        {/* Show count if any filters are selected */}
        {(selectedAttributes.length > 0 || neighborhoods.length > 0) && (
          <Box
            sx={{
              position: 'absolute',
              top: '-8px',
              right: '-8px',
              backgroundColor: '#FF4081',
              color: 'white',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {selectedAttributes.length + neighborhoods.length}
          </Box>
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleAttributeClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: '90%',
            maxWidth: 400,
            p: 2,
            borderRadius: 2,
            boxShadow: theme.shadows[5],
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h6'>Set Filters</Typography>
            <Button
              onClick={handleAttributeClose}
              variant="contained"
              startIcon={CloseIcon && <CloseIcon />}
              sx={{
                backgroundColor: '#E0B1F1',
                color: 'black',
                textTransform: 'none',
                fontWeight: 500,
                padding: '8px 16px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#D8A8E8'
                },
                '&:active': {
                  backgroundColor: '#D8A8E8'
                }
              }}
            >
              Save and close
            </Button>
          </Box>
          <Select
            value={selectedTime || ''}
            onChange={handleTimeChange}
            displayEmpty
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              height: 40,
            }}
          >
            <MenuItem value=''>Any Time</MenuItem>
            <MenuItem value='11:00'>11:00 AM</MenuItem>
            <MenuItem value='12:00'>12:00 PM</MenuItem>
            <MenuItem value='13:00'>1:00 PM</MenuItem>
            <MenuItem value='14:00'>2:00 PM</MenuItem>
            <MenuItem value='15:00'>3:00 PM</MenuItem>
            <MenuItem value='16:00'>4:00 PM</MenuItem>
            <MenuItem value='17:00'>5:00 PM</MenuItem>
            <MenuItem value='18:00'>6:00 PM</MenuItem>
            <MenuItem value='19:00'>7:00 PM</MenuItem>
            <MenuItem value='20:00'>8:00 PM</MenuItem>
            <MenuItem value='21:00'>9:00 PM</MenuItem>
            <MenuItem value='22:00'>10:00 PM</MenuItem>
          </Select>
          <Select
            value={priceRange}
            onChange={handlePriceRangeChange}
            displayEmpty
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              height: 40,
            }}
          >
            <MenuItem value=''>Under Any Price</MenuItem>
            <MenuItem value='0-0'>Free (No Minimum)</MenuItem>
            <MenuItem value='0-40'>$0 - $40 per person</MenuItem>
            <MenuItem value='0-70'>$0 - $70 per person</MenuItem>
            <MenuItem value='0-100'>$0 - $100 per person</MenuItem>
            <MenuItem value='0-150'>$0 - $150 per person</MenuItem>
            <MenuItem value='0-200'>$0 - $200 per person</MenuItem>
            <MenuItem value='200-10000'>$200+ per person</MenuItem>
          </Select>
          <TextField
            value={localPeople}
            onChange={(e) => {
              const value = e.target.value;
              // Only allow numbers
              if (/^\d*$/.test(value)) {
                handlePartySizeChange(value);
              }
            }}
            placeholder={`${localPeople || ''} people`}
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                height: 40,
              },
              '& .MuiInputBase-input': {
                padding: '8px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {PeopleIcon && <PeopleIcon />}
                </InputAdornment>
              ),
              // Add input validation to only allow numbers
              inputProps: {
                min: 1,
                max: 1000,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }
            }}
          />
          {/* <TextField
            id='zip'
            type='text'
            placeholder='Zip'
            value={zipCode}
            onChange={(e) => {
              const value = e.target.value;
              // Only allow numbers and limit to 5 digits
              if (/^\d{0,5}$/.test(value)) {
                handleZipCodeChange(value);
              }
            }}
            variant='outlined'
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                height: 40,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  {LocationOnIcon && <LocationOnIcon />}
                </InputAdornment>
              ),
            }}
          /> */}

          {/* Neighborhoods Section */}
          <Typography variant='h6' sx={{ borderBottom: '1px solid rgba(200, 200, 200, 0.2)', pb: 1 }}>
            Neighborhoods
          </Typography>
          <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1, maxHeight: '150px', overflowY: 'auto', padding: '8px 0' }}>
            {locationTypes.map((neighborhood) => {
              const iconName = attributeIcons[neighborhood.toLowerCase()];
              const IconComponent = iconName && iconComponents[iconName] ? iconComponents[iconName] : null;
              const isSelected = neighborhoods.includes(neighborhood);

              return (
                <Chip
                  key={neighborhood}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {IconComponent &&
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {React.createElement(IconComponent)}
                        </span>
                      }
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                        {neighborhood}
                      </Typography>
                    </Box>
                  }
                  onClick={() => handleNeighborhoodSelect(neighborhood)}
                  variant={isSelected ? "filled" : "outlined"}
                  clickable
                  sx={{
                    margin: '2px',
                    backgroundColor: isSelected ? theme.palette.primary.main : 'transparent',
                    color: isSelected ? 'white' : 'inherit',
                    borderColor: isSelected ? theme.palette.primary.main : 'rgba(200, 200, 200, 0.3)',
                    '&:hover': {
                      backgroundColor: isSelected ? theme.palette.primary.dark : 'rgba(200, 200, 200, 0.1)',
                    },
                    height: '28px',
                  }}
                />
              );
            })}
          </Box>

          {/* Attributes Section */}
          <Typography variant='h6' sx={{ borderBottom: '1px solid rgba(200, 200, 200, 0.2)', pb: 1 }}>
            Venue Attributes
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, maxHeight: '200px', overflowY: 'auto', padding: '8px 0' }}>
            {filteredAttributes.map((attribute) => {
              const iconName = attributeIcons[attribute.toLowerCase()];
              const IconComponent = iconName && iconComponents[iconName] ? iconComponents[iconName] : null;
              const isSelected = selectedAttributes.includes(attribute);

              return (
                <Chip
                  key={attribute}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {IconComponent &&
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {React.createElement(IconComponent)}
                        </span>
                      }
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                        {attribute}
                      </Typography>
                    </Box>
                  }
                  onClick={() => handleAttributeSelect(attribute)}
                  variant={isSelected ? "filled" : "outlined"}
                  clickable
                  sx={{
                    margin: '2px',
                    backgroundColor: isSelected ? theme.palette.primary.main : 'transparent',
                    color: isSelected ? 'white' : 'inherit',
                    borderColor: isSelected ? theme.palette.primary.main : 'rgba(200, 200, 200, 0.3)',
                    '&:hover': {
                      backgroundColor: isSelected ? theme.palette.primary.dark : 'rgba(200, 200, 200, 0.1)',
                    },
                    height: '28px',
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
