'use client';

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Typography, Modal, Select, MenuItem, CircularProgress, Grid, Chip } from '@mui/material';
import { useRouter } from 'next/navigation';
import dayjs from 'dayjs';
import SmallCustomCalendar from './small_custom_calendar';
import MobileVibeModal from './mobile_vibe_modal';
import { FetchAllVenues, FetchVenuesWithAvailability, fetchAllVenueMetadata } from '../lib/firebase/venues';
import CloseIcon from '@mui/icons-material/Close';

interface VenueProps {
    venues?: {
        Attributes: { [key: string]: boolean };
    }[];
    isOpen: boolean;
    onClose: () => void;
}

const SearchModal: React.FC<VenueProps> = ({ venues: initialVenues, isOpen, onClose }) => {
    const router = useRouter();
    const [venues, setVenues] = useState<VenueProps['venues']>(initialVenues || []);
    const [isLoading, setIsLoading] = useState(!initialVenues);
    const [date, setDate] = useState<Date | null>(dayjs().add(1, 'week').toDate());
    const [time, setTime] = useState<string | null>('19:00');
    const [partySize, setPartySize] = useState<string>('25');
    const [selectedSpaces, setSelectedSpaces] = useState<string[]>([]);
    const [selectedNeighborhoods, setSelectedNeighborhoods] = useState<string[]>([]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [availableAttributes, setAvailableAttributes] = useState<{ [key: string]: number }>({});
    const [validCombinations, setValidCombinations] = useState<Set<string>>(new Set());

    // Additional state for the new UI
    const [possibleAttributes, setPossibleAttributes] = useState<string[]>([]);
    const [attributeIcons, setAttributeIcons] = useState<Record<string, string>>({});
    const [iconComponents, setIconComponents] = useState<Record<string, React.ComponentType>>({});

    const locationTypes = [
        'greenwich village', 'midtown', 'east village', 'queens', 'soho',
        'williamsburg', 'brooklyn', 'lower east side', 'lower manhattan',
    ];

    const nonLocationTypes = [
        'wedding', 'corporate', 'no minimum spend', 'bar', 'restaurant', 'fancy', 'casual',
        'intimate gathering'
    ];

    const spaceTypes = [...nonLocationTypes].filter(type => type in availableAttributes);

    useEffect(() => {
        const fetchVenues = async () => {
            if (!initialVenues) {
                try {
                    setIsLoading(true);
                    const { venues: fetchedVenues } = await FetchAllVenues(100, null, true);
                    console.log(fetchedVenues);
                    setVenues(fetchedVenues);
                } catch (error) {
                    console.error('Error fetching venues:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchVenues();
    }, [initialVenues]);

    useEffect(() => {
        // Only calculate attributes if venues are available
        if (!venues?.length) return;

        // Calculate available attributes from venues
        const attributes: { [key: string]: number } = {};
        venues.forEach(venue => {
            Object.entries(venue.Attributes).forEach(([attr, value]) => {
                if (value) {
                    attributes[attr] = (attributes[attr] || 0) + 1;
                }
            });
        });
        setAvailableAttributes(attributes);

        // Calculate valid combinations
        const combinations = new Set<string>();
        venues.forEach(venue => {
            Object.entries(venue.Attributes)
                .filter(([_, value]) => value)
                .forEach(([attr1]) => {
                    Object.entries(venue.Attributes)
                        .filter(([_, value]) => value)
                        .forEach(([attr2]) => {
                            if (attr1 < attr2) {
                                combinations.add(`${attr1}-${attr2}`);
                            }
                        });
                });
        });
        setValidCombinations(combinations);
    }, [venues]);

    useEffect(() => {
        const fetchAttributes = async () => {
            try {
                const AllVenueMetadata = await fetchAllVenueMetadata();
                // Filter out neighborhoods from attributes
                const attributes = AllVenueMetadata.possibleAttributes.filter(
                    attr => !locationTypes.includes(attr.toLowerCase())
                );
                setPossibleAttributes(attributes);
            } catch (error) {
                console.error('Error fetching venue metadata:', error);
            }
        };
        fetchAttributes();
    }, [locationTypes]);

    // Add click outside handlers
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (isDatePickerOpen && !target.closest('.date-picker-container')) {
                setIsDatePickerOpen(false);
            }
            if (isTimePickerOpen && !target.closest('.time-picker-container')) {
                setIsTimePickerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isDatePickerOpen, isTimePickerOpen]);

    const handleCloseModal = () => {
        onClose();
    };


    const handleSearchClick = () => {
        const searchParams = new URLSearchParams();
        if (date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const dateString = `${year}-${month}-${day}`;
            searchParams.set('date', dateString);
        }
        if (time) searchParams.set('time', time);
        if (partySize) searchParams.set('size', partySize);

        // Separate attributes and neighborhoods
        const actualAttributes = selectedSpaces.filter(attr => !locationTypes.includes(attr));
        if (actualAttributes.length > 0) {
            searchParams.set('attributes', actualAttributes.join(','));
        }

        // Add neighborhoods as a separate parameter
        if (selectedNeighborhoods.length > 0) {
            searchParams.set('neighborhoods', selectedNeighborhoods.join(','));
        }

        router.push(`/search?${searchParams.toString()}`);
        handleCloseModal();
    };

    const handleSearch = () => {
        handleSearchClick();
    };

    const handleClear = () => {
        // Reset all local state
        setDate(dayjs().add(1, 'week').toDate());
        setTime('19:00');
        setPartySize('25');
        setSelectedSpaces([]);
        setSelectedNeighborhoods([]);
    };

    // Handlers for attribute and neighborhood chips
    const handleAttributeSelect = (attribute: string) => {
        setSelectedSpaces(prev =>
            prev.includes(attribute)
                ? prev.filter(attr => attr !== attribute)
                : [...prev, attribute]
        );
    };

    const handleNeighborhoodSelect = (neighborhood: string) => {
        setSelectedNeighborhoods(prev =>
            prev.includes(neighborhood)
                ? prev.filter(n => n !== neighborhood)
                : [...prev, neighborhood]
        );
    };

    // Filter attributes to exclude neighborhoods
    const filteredAttributes = useMemo(() => {
        return possibleAttributes.filter(attr => !locationTypes.includes(attr.toLowerCase()));
    }, [possibleAttributes, locationTypes]);

    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby="search-options-modal"
            aria-describedby="modal-with-search-options"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '94%', sm: '85%', md: '80%', lg: '70%' },
                    maxWidth: { xs: 600, sm: 800, md: 1000, lg: 1100 },
                    maxHeight: { xs: '98vh' },
                    overflow: 'auto',
                    bgcolor: '#2c2c54',
                    color: 'white',
                    boxShadow: '0 8px 32px rgba(224, 177, 241, 0.3)',
                    p: { xs: 2, sm: 3, md: 5 },
                    borderRadius: { xs: 2, sm: 3, md: 4 },
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { xs: 0, sm: 3, md: 4 }
                }}
            >
                {/* Close Button */}
                <Button
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        minWidth: '36px',
                        width: '36px',
                        height: '36px',
                        borderRadius: '50%',
                        padding: 0,
                        backgroundColor: 'rgba(224, 177, 241, 0.1)',
                        color: '#E0B1F1',
                        zIndex: 10,
                        '&:hover': {
                            backgroundColor: 'rgba(224, 177, 241, 0.3)',
                        }
                    }}
                >
                    <CloseIcon fontSize="small" />
                </Button>

                {isLoading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '200px'
                    }}>
                        <CircularProgress sx={{ color: '#E0B1F1' }} />
                    </Box>
                ) : (
                    <>
                        {/* Header Section */}

                        {/* Form Container */}
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            flexWrap: 'wrap',
                            gap: { xs: 2, md: 3 }
                        }}>
                            {/* Left Column - Date and Time */}
                            <Box sx={{
                                width: { xs: '100%', md: '48%' },
                                display: 'flex',
                                flexDirection: 'column',
                                gap: { xs: 2, md: 3 }
                            }}>
                                {/* Date Section - Updated Styles */}
                                <Box sx={{ width: '100%' }}>
                                    <Typography
                                        variant="h6"
                                        fontFamily="Sans"
                                        sx={{
                                            mb: { xs: 1, md: 1.5 },
                                            color: '#ffffff',
                                            fontWeight: 600,
                                            fontSize: { xs: '0.95rem', sm: '1.1rem', md: '1.2rem' }
                                        }}
                                    >
                                        When's the big day?
                                    </Typography>
                                    <Box
                                        onClick={() => setIsDatePickerOpen(true)}
                                        sx={{
                                            cursor: 'pointer',
                                            p: { xs: 1.5, md: 2 },
                                            borderRadius: '12px',
                                            bgcolor: 'rgba(224, 177, 241, 0.1)',
                                            border: '1px solid #E0B1F1',
                                            WebkitTapHighlightColor: 'transparent',
                                            touchAction: 'manipulation',
                                            userSelect: 'none',
                                            minHeight: { xs: '44px', md: '54px' },
                                            display: 'flex',
                                            alignItems: 'center',
                                            position: 'relative',
                                            zIndex: 1,
                                            transition: 'all 0.2s ease',
                                            '&:hover': {
                                                bgcolor: 'rgba(224, 177, 241, 0.15)',
                                            },
                                            '&:active': {
                                                bgcolor: 'rgba(224, 177, 241, 0.2)',
                                            }
                                        }}
                                    >
                                        <Typography sx={{ fontSize: { xs: '0.85rem', md: '0.95rem' }, fontWeight: 500 }}>
                                            {date ? date.toLocaleDateString('en-US', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            }) : 'Select a date'}
                                        </Typography>
                                    </Box>
                                    {isDatePickerOpen && (
                                        <Box
                                            className="date-picker-container"
                                            onClick={(e) => e.stopPropagation()}
                                            sx={{
                                                position: 'absolute',
                                                zIndex: 1000,
                                                mt: 1,
                                                width: { xs: '85%', sm: '80%', md: '45%' },
                                                maxWidth: '450px',
                                                bgcolor: '#2c2c54',
                                                borderRadius: '12px',
                                                border: '2px solid #E0B1F1',
                                                boxShadow: '0 12px 24px rgba(224, 177, 241, 0.5), 0 4px 8px rgba(0, 0, 0, 0.3)',
                                                backdropFilter: 'blur(8px)',
                                                isolation: 'isolate'
                                            }}
                                        >
                                            <SmallCustomCalendar
                                                selectedDate={date || new Date()}
                                                minDate={new Date()}
                                                onDateChange={(newDate) => {
                                                    if (newDate) {
                                                        setDate(newDate);
                                                        setIsDatePickerOpen(false);
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>

                                {/* Time Section - Updated Styles */}
                                <Box sx={{ width: '100%' }}>
                                    <Typography
                                        variant="h6"
                                        fontFamily="Sans"
                                        sx={{
                                            mb: { xs: 1, md: 1.5 },
                                            color: '#ffffff',
                                            fontWeight: 600,
                                            fontSize: { xs: '0.95rem', sm: '1.1rem', md: '1.2rem' }
                                        }}
                                    >
                                        What time is the party?
                                    </Typography>
                                    <Select
                                        value={time}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setTime(e.target.value as string);
                                        }}
                                        sx={{
                                            width: '100%',
                                            color: 'white',
                                            backgroundColor: 'rgba(224, 177, 241, 0.1)',
                                            borderRadius: '12px',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0B1F1',
                                                borderWidth: '1px'
                                            },
                                            '& .MuiSelect-icon': {
                                                color: '#E0B1F1'
                                            },
                                            height: { xs: '44px', md: '54px' },
                                            fontSize: { xs: '0.85rem', md: '0.95rem' },
                                            fontWeight: 500,
                                            padding: '0 12px',
                                            WebkitTapHighlightColor: 'transparent',
                                            touchAction: 'manipulation'
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#2c2c54',
                                                    color: 'white',
                                                    borderRadius: '12px',
                                                    maxHeight: '300px',
                                                    '& .MuiMenuItem-root': {
                                                        fontSize: '0.9rem',
                                                        fontWeight: 500,
                                                        padding: '8px 16px',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(224, 177, 241, 0.15)'
                                                        },
                                                        '&.Mui-selected': {
                                                            backgroundColor: 'rgba(224, 177, 241, 0.3)'
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {Array.from({ length: 24 }, (_, i) => (
                                            <MenuItem key={i} value={`${i}:00`}>
                                                {dayjs().hour(i).format('h:00 A')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>

                            {/* Right Column - Party Size and Vibe */}
                            <Box sx={{
                                width: { xs: '100%', md: '48%' },
                                display: 'flex',
                                flexDirection: 'column',
                                gap: { xs: 2, md: 3 }
                            }}>
                                {/* Party Size Section - Updated to use Select */}
                                <Box sx={{ width: '100%' }}>
                                    <Typography
                                        variant="h6"
                                        fontFamily="Sans"
                                        sx={{
                                            mb: { xs: 1, md: 1.5 },
                                            color: '#ffffff',
                                            fontWeight: 600,
                                            fontSize: { xs: '0.95rem', sm: '1.1rem', md: '1.2rem' }
                                        }}
                                    >
                                        Around how many people?
                                    </Typography>
                                    <Select
                                        value={partySize}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setPartySize(e.target.value as string);
                                        }}
                                        sx={{
                                            width: '100%',
                                            color: 'white',
                                            backgroundColor: 'rgba(224, 177, 241, 0.1)',
                                            borderRadius: '12px',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#E0B1F1',
                                                borderWidth: '1px'
                                            },
                                            '& .MuiSelect-icon': {
                                                color: '#E0B1F1'
                                            },
                                            height: { xs: '44px', md: '54px' },
                                            fontSize: { xs: '0.85rem', md: '0.95rem' },
                                            fontWeight: 500,
                                            padding: '0 12px',
                                            WebkitTapHighlightColor: 'transparent',
                                            touchAction: 'manipulation'
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#2c2c54',
                                                    color: 'white',
                                                    borderRadius: '12px',
                                                    maxHeight: '300px',
                                                    '& .MuiMenuItem-root': {
                                                        fontSize: '0.9rem',
                                                        fontWeight: 500,
                                                        padding: '8px 16px',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(224, 177, 241, 0.15)'
                                                        },
                                                        '&.Mui-selected': {
                                                            backgroundColor: 'rgba(224, 177, 241, 0.3)'
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {Array.from({ length: 20 }, (_, i) => (i + 1) * 5).map((num) => (
                                            <MenuItem key={num} value={num.toString()}>
                                                {num} people
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>

                                {/* Vibe Section */}
                                <Box sx={{ width: '100%' }}>
                                    <Typography
                                        variant="h6"
                                        fontFamily="Sans"
                                        sx={{
                                            mb: { xs: 1, md: 1.5 },
                                            color: '#ffffff',
                                            fontWeight: 600,
                                            fontSize: { xs: '0.95rem', sm: '1.1rem', md: '1.2rem' }
                                        }}
                                    >
                                        What's your vibe?
                                    </Typography>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{
                                            borderRadius: '12px',
                                            width: '100%',
                                            outline: 'none'
                                        }}>
                                            <MobileVibeModal
                                                selectedSpaces={[...selectedSpaces, ...selectedNeighborhoods]}
                                                venues={venues}
                                                spaceTypes={spaceTypes || []}
                                                locationSpaces={locationTypes || []}
                                                onSpaceTypeChange={(newTypes: string[]) => {
                                                    // Simply replace both arrays with the new selection
                                                    // and separate by type
                                                    const newNeighborhoods = newTypes.filter(type =>
                                                        locationTypes.includes(type));
                                                    const newAttributes = newTypes.filter(type =>
                                                        !locationTypes.includes(type));

                                                    setSelectedNeighborhoods(newNeighborhoods);
                                                    setSelectedSpaces(newAttributes);
                                                }}
                                                onClose={() => { }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        {/* Search Button - Full Width at Bottom */}
                        <Box sx={{
                            width: '100%',
                            mt: { xs: 2, sm: 3, md: 4 },
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                onClick={handleClear}
                                sx={{
                                    backgroundColor: 'rgba(224, 177, 241, 0.1)',
                                    color: '#ffffff',
                                    textTransform: 'none',
                                    padding: { xs: '10px 16px', sm: '12px 24px' },
                                    borderRadius: '8px',
                                    fontSize: { xs: '0.9rem', sm: '1rem' },
                                    fontWeight: 'bold',
                                    fontFamily: 'Sans',
                                    border: '1px solid #E0B1F1',
                                    '&:hover': {
                                        backgroundColor: 'rgba(224, 177, 241, 0.2)',
                                    },
                                }}
                            >
                                Clear All
                            </Button>

                            <Button
                                onClick={handleSearch}
                                sx={{
                                    backgroundColor: '#E0B1F1',
                                    color: '#2c2c54',
                                    textTransform: 'none',
                                    padding: { xs: '10px 16px', sm: '12px 24px' },
                                    borderRadius: '8px',
                                    fontSize: { xs: '0.9rem', sm: '1rem' },
                                    fontWeight: 'bold',
                                    fontFamily: 'Sans',
                                    '&:hover': {
                                        backgroundColor: '#d9a9eb',
                                    },
                                    '&:active': {
                                        backgroundColor: '#c99ce0',
                                    }
                                }}
                            >
                                Search
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default SearchModal;
