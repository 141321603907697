'use client'

import React, { useState, useCallback, memo, useMemo } from 'react';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import IconButton from '@mui/material/IconButton';
import dynamic from 'next/dynamic';
import ShareIcon from '@mui/icons-material/Share';
import LinkWithLoading from './link_with_loading';
import SmallLogo from './small_logo';

// Lazy load components that aren't needed immediately
const SearchModal = dynamic(() => import('./search_modal'), { ssr: false });
const HamburgerNavbarMenu = dynamic(() => import('./hamburger_navbar_menu'), { ssr: false });


// Memoized search button to prevent unnecessary re-renders
const SearchButton = memo(({ onClick }: { onClick: (e: React.MouseEvent) => void }) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 2
  }}>
    <Box
      sx={{
        borderRadius: '40px',
        overflow: 'hidden',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        maxWidth: '250px',
        width: '100%'
      }}
    >
      <Button
        variant="contained"
        onClick={onClick}
        sx={{
          borderRadius: 40,
          backgroundColor: '#dddddd',
          color: 'black',
          textTransform: 'none',
          fontFamily: 'Sans',
          fontSize: '0.9rem',
          padding: '8px 16px',
          minHeight: '40px',
          width: '100%',
          '&:hover': {
            backgroundColor: 'white',
            color: 'black',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <span>Search</span>
      </Button>
    </Box>
  </Box>
));
SearchButton.displayName = 'SearchButton';

// Memoized desktop navigation to prevent unnecessary re-renders
const DesktopNav = memo(() => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <LinkWithLoading href="/login" passHref>
      <Button
        variant="text"
        sx={{
          color: 'white',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        Venue Owner Portal
      </Button>
    </LinkWithLoading>
    <HamburgerNavbarMenu />
    <LinkWithLoading href="/profile" passHref>
      <IconButton
        color="inherit"
        sx={{ color: 'gray' }}
        aria-label="Profile"
      >
        <AccountCircleOutlined />
      </IconButton>
    </LinkWithLoading>
  </Box>
));
DesktopNav.displayName = 'DesktopNav';

// Memoized mobile share button
const MobileShareButton = memo(({ onClick }: { onClick: (e: React.MouseEvent) => void }) => (
  <LinkWithLoading href="#" passHref>
    <IconButton
      color="inherit"
      sx={{
        color: 'white',
        padding: '6px',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      }}
      aria-label="Share"
      onClick={onClick}
    >
      <ShareIcon />
    </IconButton>
  </LinkWithLoading>
));
MobileShareButton.displayName = 'MobileShareButton';

// Main component with memoized children
export function SearchAndShareNavBar() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  // Memoized event handlers
  const handleSearchClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsSearchModalOpen(true);
  }, []);

  const handleShareClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    if (navigator.share) {
      navigator.share({
        title: document.title,
        url: window.location.href
      }).catch(console.error);
    } else {
      console.log('Web Share API not supported');
    }
  }, []);

  // Memoized header styles to prevent recreation on render
  const headerStyles = useMemo(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    marginBottom: { xs: 0 }
  }), []);

  const navContainerStyles = useMemo(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
  }), []);

  const dividerStyles = useMemo(() => ({
    width: '100%',
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }), []);

  // Memoized navigation section
  const navigationSection = useMemo(() => (
    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
      {isDesktop ? <DesktopNav /> : <MobileShareButton onClick={handleShareClick} />}
    </Box>
  ), [isDesktop, handleShareClick]);

  return (
    <Box sx={headerStyles}>
      <Box sx={navContainerStyles}>
        <SmallLogo />

        {isDesktop && <SearchButton onClick={handleSearchClick} />}

        {navigationSection}
      </Box>

      <Box sx={dividerStyles} />

      {isSearchModalOpen && (
        <SearchModal
          venues={undefined}
          isOpen={isSearchModalOpen}
          onClose={() => setIsSearchModalOpen(false)}
        />
      )}
    </Box>
  );
}
