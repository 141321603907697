'use client'

import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { HorizontalFeaturedCard, HorizontalFeaturedCardSkeleton } from './horizontal_featured_card';
import { Venue } from '../../../shared/models';
import { logFirebaseEvent } from '../lib/firebase/app';

interface VenueShowcaseScrollableProps {
    categoriesAndVenuesIDsInOrder: {
        Title: string;
        Subtitle: string;
        VenueIDs: string[];
    }[];
    attributeIcons: Record<string, string>;
    venues: Venue[];
}

// Add interface for MemoizedVenueCard props
interface VenueCardProps {
    venue: Venue;
    attributeIcons: Record<string, string>;
    categoryTitle: string;
    categoryIndex: number;
    positionInCategory: number;
}

// Memoized card component
const MemoizedVenueCard = React.memo<VenueCardProps>(({
    venue,
    attributeIcons,
    categoryTitle,
    categoryIndex,
    positionInCategory
}) => (
    <Box
        onClick={() => {
            logFirebaseEvent('category_venue_card_click', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                category_title: categoryTitle,
                category_position: categoryIndex,
                position_in_category: positionInCategory
            });
        }}
        sx={{
            minWidth: {
                xs: 'calc((100vw - 48px) / 3.5)',
                sm: 'calc((100vw - 64px) / 3.5)',
                md: 'calc((100vw - 96px) / 3.5)'
            },
            cursor: 'pointer'
        }}
    >
        <HorizontalFeaturedCard
            venue={venue}
            venueBookedTimeSlots={{}}
            attributeIcons={attributeIcons}
        />
    </Box>
));

const VenueShowcaseScrollable: React.FC<VenueShowcaseScrollableProps> = ({
    categoriesAndVenuesIDsInOrder,
    attributeIcons,
    venues
}) => {
    const [visibleCategories, setVisibleCategories] = useState<number>(3); // Initially show 3 categories
    const [visibleRanges, setVisibleRanges] = useState<Record<number, { start: number; end: number }>>({});
    const scrollContainerRefs = useRef<(HTMLDivElement | null)[]>([]);
    const categoriesContainerRef = useRef<HTMLDivElement>(null);

    const INITIAL_ITEMS = 4;
    const ITEMS_PER_LOAD = 4;
    const BUFFER_ITEMS = 4; // Number of items to keep in buffer

    // Add new state for tracking scroll positions
    const [scrollStates, setScrollStates] = useState<Record<number, { canScrollLeft: boolean; canScrollRight: boolean }>>({});

    // Memoize visible venues calculation
    const getVisibleVenues = useMemo(() => {
        return (categoryIndex: number) => {
            const category = categoriesAndVenuesIDsInOrder[categoryIndex];
            const range = visibleRanges[categoryIndex] || { start: 0, end: INITIAL_ITEMS };

            return category.VenueIDs.slice(range.start, range.end)
                .map(id => venues.find(v => v.ID === id))
                .filter((v): v is Venue => v !== undefined);
        };
    }, [categoriesAndVenuesIDsInOrder, venues, visibleRanges]);

    const loadMoreVenues = useCallback((categoryIndex: number) => {
        const category = categoriesAndVenuesIDsInOrder[categoryIndex];
        const currentRange = visibleRanges[categoryIndex] || { start: 0, end: INITIAL_ITEMS };

        // Remove items that are far behind viewport
        const newStart = Math.max(0, currentRange.end - ITEMS_PER_LOAD - BUFFER_ITEMS);
        const newEnd = Math.min(currentRange.end + ITEMS_PER_LOAD, category.VenueIDs.length);

        setVisibleRanges(prev => ({
            ...prev,
            [categoryIndex]: { start: newStart, end: newEnd }
        }));
    }, [categoriesAndVenuesIDsInOrder, visibleRanges]);

    // Modify useEffect for scroll handling
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '100px',
            threshold: 0.1
        };

        const observers = scrollContainerRefs.current.map((ref, categoryIndex) => {
            if (!ref) return null;

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const currentRange = visibleRanges[categoryIndex] || { start: 0, end: INITIAL_ITEMS };
                        const totalVenues = categoriesAndVenuesIDsInOrder[categoryIndex].VenueIDs.length;

                        if (currentRange.end < totalVenues) {
                            loadMoreVenues(categoryIndex);
                        }
                    }
                });
            }, options);

            const sentinel = ref.querySelector('.scroll-sentinel');
            if (sentinel) observer.observe(sentinel);
            return observer;
        });

        return () => observers.forEach(observer => observer?.disconnect());
    }, [loadMoreVenues, categoriesAndVenuesIDsInOrder, visibleRanges]);

    // Add new useEffect for category lazy loading
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '200px',
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const newVisibleCount = Math.min(
                        visibleCategories + 3,
                        categoriesAndVenuesIDsInOrder.length
                    );
                    setVisibleCategories(newVisibleCount);

                    // Log category view events
                    const newCategories = categoriesAndVenuesIDsInOrder.slice(visibleCategories, newVisibleCount);
                    newCategories.forEach(category => {
                        logFirebaseEvent('category_view', {
                            category_title: category.Title,
                            category_position: visibleCategories,
                            venues_count: category.VenueIDs.length
                        });
                    });

                    // Force complete loading if we're close to the end
                    if (newVisibleCount >= categoriesAndVenuesIDsInOrder.length - 3) {
                        setVisibleCategories(categoriesAndVenuesIDsInOrder.length);
                    }
                }
            });
        }, options);

        if (categoriesContainerRef.current) {
            observer.observe(categoriesContainerRef.current);
        }

        return () => observer.disconnect();
    }, [categoriesAndVenuesIDsInOrder.length, visibleCategories]);

    // Add a safety check for cases where the observer fails to trigger
    useEffect(() => {
        // If we're at the bottom of the page, ensure all categories are loaded
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200) {
                setVisibleCategories(categoriesAndVenuesIDsInOrder.length);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [categoriesAndVenuesIDsInOrder.length]);

    // Add new function to check scroll position
    const checkScrollPosition = useCallback((categoryIndex: number) => {
        const container = scrollContainerRefs.current[categoryIndex];
        if (!container) return;

        const canScrollLeft = container.scrollLeft > 0;
        const canScrollRight = container.scrollLeft < (container.scrollWidth - container.clientWidth);

        setScrollStates(prev => ({
            ...prev,
            [categoryIndex]: { canScrollLeft, canScrollRight }
        }));
    }, []);

    // Modify handleScroll to update scroll state after scrolling
    const handleScroll = (categoryIndex: number, direction: 'left' | 'right') => {
        const container = scrollContainerRefs.current[categoryIndex];
        if (!container) return;

        // Track scroll interaction
        logFirebaseEvent('category_scroll', {
            category_title: categoriesAndVenuesIDsInOrder[categoryIndex].Title,
            scroll_direction: direction,
            category_position: categoryIndex
        });

        const scrollAmount = 400;
        const targetScroll = container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);

        container.scrollTo({
            left: targetScroll,
            behavior: 'smooth'
        });

        // Update scroll state after animation
        setTimeout(() => checkScrollPosition(categoryIndex), 500);
    };

    // Add scroll event listener to each container
    useEffect(() => {
        scrollContainerRefs.current.forEach((ref, index) => {
            if (!ref) return;

            const handleScrollEvent = () => checkScrollPosition(index);
            ref.addEventListener('scroll', handleScrollEvent);

            // Initial check
            checkScrollPosition(index);

            return () => ref.removeEventListener('scroll', handleScrollEvent);
        });
    }, [checkScrollPosition]);

    // Memoize category rendering
    const renderCategory = useCallback((category: typeof categoriesAndVenuesIDsInOrder[0], categoryIndex: number) => (
        <Box key={categoryIndex} sx={{ width: '100%', mb: 6 }}>
            <Typography
                variant="h2"
                fontFamily='Thick'
                color='#FFFFFF'
                sx={{
                    marginBottom: 2,
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                    fontSize: { xs: '2rem', md: '2.5rem' }
                }}
            >
                {category.Title}
            </Typography>

            <Typography
                variant="h5"
                fontFamily='Sans'
                color='#AAAAAA'
                sx={{
                    marginBottom: 4,
                    maxWidth: '800px',
                    fontSize: { xs: '1rem', md: '1.25rem' }
                }}
            >
                {category.Subtitle}
            </Typography>

            <Box sx={{ position: 'relative' }}>
                <Box
                    ref={(el: HTMLDivElement | null) => {
                        if (el) {
                            scrollContainerRefs.current[categoryIndex] = el;
                        }
                    }}
                    sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        scrollSnapType: 'x mandatory',
                        '-webkit-overflow-scrolling': 'touch',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        pb: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            '& > *': {
                                flex: '0 0 auto', // Prevent items from shrinking or growing
                                width: {
                                    xs: '300px',
                                    sm: '350px',
                                    md: '400px'
                                }
                            }
                        }}
                    >
                        {getVisibleVenues(categoryIndex).map((venue, index) => (
                            <MemoizedVenueCard
                                key={venue.ID}
                                venue={venue}
                                attributeIcons={attributeIcons}
                                categoryTitle={category.Title}
                                categoryIndex={categoryIndex}
                                positionInCategory={index}
                            />
                        ))}

                        {/* Sentinel */}
                        {(visibleRanges[categoryIndex]?.end || INITIAL_ITEMS) < category.VenueIDs.length && (
                            <Box className="scroll-sentinel">
                                <HorizontalFeaturedCardSkeleton />
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* Navigation Arrows */}
                {scrollStates[categoryIndex]?.canScrollLeft && (
                    <Box
                        onClick={() => handleScroll(categoryIndex, 'left')}
                        sx={{
                            position: 'absolute',
                            left: { xs: 8, md: 16 },
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2,
                            width: { xs: '48px', md: '56px' },
                            height: { xs: '48px', md: '56px' },
                            borderRadius: '50%',
                            background: 'rgba(0, 0, 0, 0.75)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.4), 0 12px 32px rgba(0, 0, 0, 0.3)',
                            border: '2px solid rgba(255, 255, 255, 0.15)',
                            backdropFilter: 'blur(16px)',
                            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.85)',
                                transform: 'translateY(-50%) scale(1.12)',
                                boxShadow: '0 12px 32px rgba(0, 0, 0, 0.5), 0 16px 40px rgba(0, 0, 0, 0.4)',
                            },
                            '&:active': {
                                transform: 'translateY(-50%) scale(0.92)',
                                background: 'rgba(0, 0, 0, 0.95)',
                            },
                            '&::after': {
                                content: '"←"',
                                fontSize: '1.5rem',
                                color: 'rgba(255, 255, 255, 0.9)',
                                fontWeight: 'bold',
                            }
                        }}
                    />
                )}
                {scrollStates[categoryIndex]?.canScrollRight && (
                    <Box
                        onClick={() => handleScroll(categoryIndex, 'right')}
                        sx={{
                            position: 'absolute',
                            right: { xs: 8, md: 16 },
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2,
                            width: { xs: '48px', md: '56px' },
                            height: { xs: '48px', md: '56px' },
                            borderRadius: '50%',
                            background: 'rgba(0, 0, 0, 0.75)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.4), 0 12px 32px rgba(0, 0, 0, 0.3)',
                            border: '2px solid rgba(255, 255, 255, 0.15)',
                            backdropFilter: 'blur(16px)',
                            transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.85)',
                                transform: 'translateY(-50%) scale(1.12)',
                                boxShadow: '0 12px 32px rgba(0, 0, 0, 0.5), 0 16px 40px rgba(0, 0, 0, 0.4)',
                            },
                            '&:active': {
                                transform: 'translateY(-50%) scale(0.92)',
                                background: 'rgba(0, 0, 0, 0.95)',
                            },
                            '&::after': {
                                content: '"→"',
                                fontSize: '1.5rem',
                                color: 'rgba(255, 255, 255, 0.9)',
                                fontWeight: 'bold',
                            }
                        }}
                    />
                )}
            </Box>
        </Box>
    ), [getVisibleVenues, attributeIcons, visibleRanges, scrollStates]);

    return (
        <div ref={categoriesContainerRef} className="w-full overflow-x-hidden">
            {categoriesAndVenuesIDsInOrder
                .slice(0, visibleCategories)
                .map((category, index) => renderCategory(category, index))}

            {/* Loading indicator for categories */}
            {visibleCategories < categoriesAndVenuesIDsInOrder.length && (
                <Box sx={{ width: '100%', mb: 4 }}>
                    {/* Skeleton Title */}
                    <Box
                        sx={{
                            height: { xs: '2rem', md: '2.5rem' },
                            width: '60%',
                            mb: 2,
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '0.5rem',
                            animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
                            '@keyframes pulse': {
                                '0%, 100%': { opacity: 1 },
                                '50%': { opacity: 0.5 },
                            },
                        }}
                    />

                    {/* Skeleton Subtitle */}
                    <Box
                        sx={{
                            height: { xs: '1rem', md: '1.25rem' },
                            width: '40%',
                            mb: 4,
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '0.5rem',
                            animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
                        }}
                    />

                    {/* Skeleton Cards Container */}
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        overflowX: 'hidden'
                    }}>
                        {[...Array(4)].map((_, index) => (
                            <Box
                                key={index}
                                sx={{
                                    minWidth: { xs: '300px', sm: '350px', md: '400px' },
                                }}
                            >
                                <HorizontalFeaturedCardSkeleton />
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </div>
    );
};

export default React.memo(VenueShowcaseScrollable);

