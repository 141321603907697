'use client';

import React, { useState, useEffect } from 'react';
import { Dayjs } from 'dayjs';
import {
  LocalizationProvider,
  MobileDatePicker,
  loadAdapterDayjs
} from './lazy/date-pickers';
import {
  DatePickerProps as MUIDatePickerProps,
} from '@mui/x-date-pickers';
import { TextField, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useSearchParams } from 'next/navigation';
import LoadingLogo from './loading_logo';

interface SelectDatePickerProps
  extends Omit<MUIDatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> {
  minWidth?: string;
}

export function SelectDatePicker(props: SelectDatePickerProps) {
  const [open, setOpen] = useState(false);
  const [adapter, setAdapter] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadAdapter() {
      setIsLoading(true);
      const AdapterClass = await loadAdapterDayjs();
      setAdapter(new AdapterClass());
      setIsLoading(false);
    }

    loadAdapter();
  }, []);

  const searchParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams();

  const initialDate = adapter && searchParams.get('date')
    ? adapter.date(searchParams.get('date'))
    : null;

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      const formattedDate = newDate.format('YYYY-MM-DD');
      if (window.location.pathname === '/') {
        window.location.href = `/search?date=${formattedDate}`;
      } else if (window.location.pathname === '/search') {
        searchParams.set('date', formattedDate);
        const newSearchParams = searchParams.toString();
        window.location.search = `?${newSearchParams}`;
      }
    }
  };

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <LocalizationProvider dateAdapter={adapter?.constructor as any}>
      <MobileDatePicker
        {...props}
        value={initialDate}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={handleDateChange}
        minDate={adapter.date()}
        slots={{
          textField: (params) => (
            <TextField
              {...params}
              label=''
              variant='outlined'
              sx={{
                minWidth: props.minWidth || '25%',
                backgroundColor: '#e3f2fd',
                borderRadius: '8px 0 0 8px',
                height: '40px',
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  '& fieldset': {
                    borderRight: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '8px',
                },
              }}
            />
          ),
          actionBar: () => null,
          toolbar: () => null,
        }}
      />
    </LocalizationProvider>
  );
}
