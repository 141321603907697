'use client';


import { Button, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { Box, Typography, IconButton } from '@mui/material';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { SearchFilters } from './search_filters';
import { SearchFiltersWithPopup } from './search_filters_with_popup';
import React from 'react';
import HamburgerNavbarMenu from './hamburger_navbar_menu';
import { DiscoBallWithConsistentLoading } from './disco_ball';
import LinkWithLoading from './link_with_loading';

export function SearchNavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: 4,
          marginRight: 4,
          px: 0,
          gap: 2
        }}
      >
        {/* Logo Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', minWidth: '120px' }}>
          <Box sx={{ position: 'absolute', width: '60px', height: '60px', left: 0, top: '50%', transform: 'translateY(-50%)' }}>
            <DiscoBallWithConsistentLoading quality="low" size="small" />
          </Box>
          <LinkWithLoading href='/' passHref>
            <Box
              component="img"
              src="/img/litty_logo_v2_twostars_shadow.png"
              alt="Litty Logo"
              sx={{
                width: '45px',
                height: 'auto',
                position: 'absolute',
                zIndex: 2,
                transform: 'translateY(10px)',
                marginLeft: '33px',
                marginTop: '-18px',
              }}
            />
          </LinkWithLoading>
        </Box>

        {/* Search Filters Section */}
        {isMobile === null ? (
          <Skeleton
            variant='rounded'
            width='60%'
            height={60}
            sx={{
              borderRadius: 30,
              bgcolor: 'rgba(255, 255, 255, 0.1)'
            }}
          />
        ) : isMobile ? (
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <SearchFiltersWithPopup onFilterChange={() => {
              // This will be handled by the useEffect in SearchContent
              // that watches for isDirty and other filter changes
            }} />
          </Box>
        ) : (
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <SearchFilters onFilterChange={() => {
              // This will be handled by the useEffect in SearchContent
              // that watches for isDirty and other filter changes
            }} />
          </Box>
        )}

        {/* Account Section */}
        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: '120px', justifyContent: 'flex-end' }}>
            <HamburgerNavbarMenu />
            <IconButton
              color="inherit"
              sx={{ ml: 1, color: 'gray' }}
              aria-label="Profile"
              onClick={() => window.open('/profile', '_blank')}
            >
              <AccountCircleOutlined />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
}
